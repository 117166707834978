import axios from "axios";
import {
  mensajeError,
  mensajeErrorBloqueado,
  mensajeErrorNoVerificado,
  mensajeErrorPersonalizado,
  mensajeErrorUsuarioNoEncontrado,
} from "../../helpers/mensajesSwalError";
import Swal from "sweetalert2";
import { mensajeExitoCargando, mesanjeExitoPersonalizado } from "../../helpers/mensajesSwalExito";

const obtenerLogin = (email, password) => {
  const loginBD = async (email, password) => {
    mensajeExitoCargando()
    const datos = { email, password };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        datos
      );
      return [true, response.data.usuario_id, response.data.rol ,response.headers["authorization"]]
    } catch (error) {
      if (error.status === 404) {
        mensajeErrorUsuarioNoEncontrado();
      } else if (error.status === 401) {
        mensajeErrorBloqueado();
      } else if (error.status === 403) {
        mensajeErrorNoVerificado(error.data);
      } else {
        mensajeError();
      }
      return [false, null,null,null]
    }
  };

  if (email !== "" && password !== "") {
    return loginBD(email, password);
  } else {
    Swal.fire({
      icon: "error",
      title: "Error",
      html: `<p style="color: red;">${
        email === ""
          ? "El email no puede estar vacío"
          : ""
      }</p> 
           <p style="color: red;">${
             password === ""
               ? "La contraseña no puede estar vacio"
               : ""
           }</p>`,
    });
    return [false, null,null,null]
  }
};

const recuperarCuenta = (email) => {
  const recoveryAcount = async (email) => {
    const datos = { email }
    try{
      mensajeExitoCargando()
      await axios.post(
        `${process.env.REACT_APP_API_URL}/verificar-usuario`,
        datos
      );
      mesanjeExitoPersonalizado("Se le envio el correo correctamente.")
    }catch(error){
      if(error.status === 400){
        mensajeErrorPersonalizado("Debes escribir un correo válido.")
      }else if(error.status === 404){
        mensajeErrorPersonalizado("No se encontro el correo.")
      }else{
        mensajeError()
      }
    }
  }
  recoveryAcount(email)
}

export { obtenerLogin, recuperarCuenta };
