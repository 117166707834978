const formatoFecha = (fechaString) => {
  const fecha = new Date(fechaString);
  return fecha.toLocaleDateString("es-ES", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const formatoHora = (fechaString) => {
  const fecha = new Date(fechaString);
  return fecha.toLocaleTimeString("es-ES", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

const formatoMonedas = (precio) => {
  const precioFormateadoCLP = new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
    minimumFractionDigits: 0,
  }).format(precio);
  return precioFormateadoCLP;
};

const formatoFechaBD = (fecha) => {
  const año = fecha.getFullYear();
  const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
  const dia = String(fecha.getDate()).padStart(2, '0');

  return `${año}-${mes}-${dia}`
}

export { formatoFecha, formatoHora, formatoMonedas, formatoFechaBD };
