import axios from "axios";
import Swal from "sweetalert2";

const buscarLocalAdmin = async () => {
  const token = localStorage.getItem("token");
  const config = {
    headers:{
        Authorization: token
    }
  };
  try {
    const respuesta = await axios.get(
      `${process.env.REACT_APP_API_URL}/administrador/locales`,
      config
    );
    return [true, respuesta.data, respuesta.status];
  } catch (error) {
    console.log(error.status)
    return [false, null, error.status];
  }
};

const buscarEmpleadosAdministrador = async () => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
        Authorization: token
    }
  };
  try {
    const respuesta = await axios.get(
      `${process.env.REACT_APP_API_URL}/administrador/empleados/administradores`,
      config
    );
    return [true, respuesta.data, respuesta.status];
  } catch (error) {
    return [false, null, error.status];
  }
};

const buscarEmpleados = async () => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
        Authorization: token
    }
  };
  try {
    const respuesta = await axios.get(
      `${process.env.REACT_APP_API_URL}/administrador/empleados`,
      config
    );
    return [true, respuesta.data, respuesta.status];
  } catch (error) {
    return [false, null, error.status];
  }
}

const bloquearUsuario = async (id) => {
  return new Promise((resolve) => {
    Swal.fire({
      title: "Estas seguro?",
      text: "Deseas bloquear el empleado!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Bloquear!",
    }).then((result) => {
      if (result.isConfirmed) {
        const bloquearEmp = async () => {
          const token = localStorage.getItem("token");
          const config = {
            headers: {
              Authorization: `${token}`,
            },
          };
          const data = {
            id
          };
          try {
            await axios.post(
              `${process.env.REACT_APP_API_URL}/administrador/empleados/estados`,
              data,
              config
            );
            Swal.fire({
              title: "Bloqueado!",
              text: "Has bloqueado el empleado con exito.",
              icon: "success",
            });
            resolve([true, 200]);
          } catch (error) {
            Swal.fire({
              title: "Error",
              text: "Ocurrió un problema al intentar bloquear al empleado.",
              icon: "error",
            });
            resolve ([false, error.status]);
          }
        };
        return bloquearEmp();
      }
    });
  })
};

const habilitarUsuario = async (id) => {
  return new Promise((resolve) => {
    Swal.fire({
      title: "Estas seguro?",
      text: "Deseas Habilitar al empleado!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Habilitar!",
    }).then((result) => {
      if (result.isConfirmed) {
        const habilitarEmp = async () => {
          const token = localStorage.getItem("token");
          const config = {
            headers: {
              Authorization: `${token}`,
            },
          };
          const data = {
            id,
          };
          try {
            await axios.post(
              `${process.env.REACT_APP_API_URL}/administrador/empleados/estados`,
              data,
              config
            );
            Swal.fire({
              title: "Bloqueado!",
              text: "Has habilitado el empleado con exito.",
              icon: "success",
            });
  
            resolve ([true, 200]);
          } catch (error) {
            Swal.fire({
              title: "Error",
              text: "Ocurrió un problema al intentar hhabilitar al empleado.",
              icon: "error",
            });
            resolve( [false, error.status]);
          }
        };
  
        return habilitarEmp();
      }
    });
  })
};

export { buscarLocalAdmin, buscarEmpleadosAdministrador, bloquearUsuario, habilitarUsuario, buscarEmpleados };
