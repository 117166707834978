import { useEffect, useState } from "react";
import { useAuth } from "../../../AuthContext";
import {
  Table,
  Button,
  Modal,
  Container,
  Row,
  Col,
  Form,
  Pagination,
} from "react-bootstrap";
import Carga from "../../../componentes/cargando/Cargando";
import { formatoFecha, formatoMonedas } from "../../../helpers/formatos";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { es } from "date-fns/locale";
import Swal from "sweetalert2";
import { buscarVentasPorDueno } from "../../../service/dueno/buscarVentas";

const VentasEmpleados = () => {
  const { verificacionToken } = useAuth();
  const [ loading, setLoading ] = useState(true)
  const [ventasDiarias, setVentasDiarias] = useState([]);
  const [show, setShow] = useState(false);
  const [cargandoVentasDiariasAdmin, setCargandoVentasDiariasAmin] =
    useState(true);
  const [detalles, setDetalles] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [ totalVentas, setTotalVentas ] = useState(0)
  const [ fechaInicio, setFechaInicio ] = useState(new Date())
  const [ fechaFin, setFechaFin ] = useState(new Date())

  useEffect(() => {
    verificacionToken("ROLE_DUENO");
    setLoading(false)
    buscarVentas(currentPage);
  }, [currentPage]);

  const buscarVentas = async (page) => {
    const ventasDiariasEmpleados = await buscarVentasPorDueno(page,fechaInicio, fechaFin);
    if (ventasDiariasEmpleados === null) {
      setCargandoVentasDiariasAmin(false);
    } else {
      setVentasDiarias(ventasDiariasEmpleados[0]);
      setTotalVentas(ventasDiariasEmpleados[1])
      setTotalPages(ventasDiariasEmpleados[2]);
      setCurrentPage(ventasDiariasEmpleados[3]);
      setCargandoVentasDiariasAmin(false);
    }
  };

  const handleShowDetalles = (detalles) => {
    setDetalles(detalles);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const handleDateChangeUno = (e) => {
    setFechaInicio(e)
  };

  const handleDateChangeDos = (e) => {
    setFechaFin(e)
  };

  const filterDataByDate = () => {
    if (fechaInicio > fechaFin) {
      Swal.fire({
        title: "Error",
        text: "La fecha incial no puede supera a la final",
        icon: "error"
      });
    }else{
      setCargandoVentasDiariasAmin(true);
      buscarVentas(currentPage)
    }
  };

  const datePickerStyle = {
    zIndex: 1050,
  };

  if(loading){
    return(
      <Carga/>
    )
  }

  return (
    <div>
      {cargandoVentasDiariasAdmin ? (
        <Carga />
      ) : (
        <div className="container mt-4">
          {ventasDiarias.length === 0 ? (
            <div className="container mt-5">
              <div
                className="alert alert-warning alert-dismissible fade show"
                role="alert"
              >
                <strong>¡Atención!</strong> No se han realizado ventas en el
                período seleccionado.
              </div>
            </div>
          ) : (
            <div>
              <h2 className="mb-4">Lista de Ventas</h2>
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Fecha</th>
                    <th>Monto Total</th>
                    <th>Detalles</th>
                  </tr>
                </thead>
                <tbody>
                  {ventasDiarias.map((venta) => (
                    <tr key={venta.id}>
                      <td>{venta.nombre}</td>
                      <td>{formatoFecha(venta.createdAt)}</td>
                      <td>{formatoMonedas(venta.precioTotal)}</td>
                      <td>
                        <Button
                          variant="info"
                          onClick={() => handleShowDetalles(venta.pagos)}
                        >
                          Ver Detalles
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {totalVentas >= 10?(
                <Row className="my-1">
                <Col md={12} className="d-flex justify-content-center">
                  <Pagination>
                    <Pagination.Prev
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                    />
                    {[...Array(totalPages).keys()].map((number) => (
                      <Pagination.Item
                        key={number + 1}
                        active={number + 1 === currentPage}
                        onClick={() => setCurrentPage(number + 1)}
                      >
                        {number + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() =>
                        setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                      }
                    />
                  </Pagination>
                </Col>
              </Row>
              ):(<div></div>)}

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Detalles de la Venta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Método</th>
                        <th>Monto</th>
                      </tr>
                    </thead>
                    <tbody>
                      {detalles.map((detalle, index) => (
                        <tr key={index}>
                          <td>{detalle.metodo_pago.metodoPago}</td>
                          <td>{formatoMonedas(detalle.total)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
          <Container>
            <Row className="my-4">
              <Col md={5} className="mx-auto">
                <Form>
                  <Form.Group controlId="formDate">
                    <Form.Label>Selecciona una fecha Inicio</Form.Label>
                    <DatePicker
                      selected={fechaInicio}
                      onChange={handleDateChangeUno}
                      locale={es}
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                      popperClassName="bootstrap-datepicker"
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={5} className="mx-auto">
                <Form>
                  <Form.Group controlId="formDate">
                    <Form.Label>Selecciona una fecha Final</Form.Label>
                    <DatePicker
                      selected={fechaFin}
                      onChange={handleDateChangeDos}
                      locale={es}
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                      popperClassName="bootstrap-datepicker datepicker-custom"
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Button
                className="mt-3"
                variant="primary"
                onClick={() => filterDataByDate()}
              >
                Filtrar
              </Button>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
};

export default VentasEmpleados;