import React, { useEffect, useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import Carga from "../../../componentes/cargando/Cargando";

const DashBoardDueno = () => {
  const navigate = useNavigate();
  const { isAuthenticated, verificacionToken } = useAuth();
  const [ cargando, setCargando ] = useState(true)

  useEffect(() => {
    verificacionToken("ROLE_DUENO");
    setCargando(false)
  }, []);

  return (
    <div>
      {cargando?(
        <Carga/>
      ):(
<div className="container text-center">
    {isAuthenticated?(
      <div className="row">
        <div className="col-12 col-md-3">
          <div
            className="btn-group-vertical sticky-top"
            role="group"
            aria-label="Vertical button group"
          >
            <Link
              to="/dueno/empleados"
              className="btn btn-outline-success w-100 mb-2"
            >
              Registro de Empleados
            </Link>
            <Link
              to="/dueno/administrador"
              className="btn btn-outline-success w-100 mb-2"
            >
              Registro de Administrador
            </Link>
            <Link
              to="/dueno/ventas-empleados"
              className="btn btn-outline-success w-100 mb-2"
            >
              Ver Ventas Diarias
            </Link>
            <Link
              to="/dueno/ventasLocales"
              className="btn btn-outline-success w-100 mb-2"
            >
              Ver Ventas Locales
            </Link>
            <button
              type="button"
              className="btn btn-outline-success w-100 mb-2"
              onClick={() => navigate("/dueno")}
            >
              Volver Principal
            </button>
          </div>
        </div>
        <div className="col-12 col-md-9">
          <Outlet /> {/* Renderiza la vista activa según la ruta */}
        </div>
      </div>)
    :(<div>Cargando...</div>)
    }</div>
      )}
    </div>
  );
};

export default DashBoardDueno;
