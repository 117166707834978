import React from "react";
import { Routes, Route } from "react-router-dom";
import DashBoardDueno from "../layouts/DashBoardDueno.js";
import ListaEmpleado from "../admin-empleados/adminEmpleadosDueno.js";
import ListaAdministrador from "../admin-empleados/adminAdministradorDueno.js";
import VentasEmpleados from "../ventas/ventasEmpleados.js";

function RutasDueno() {
  return (
    <Routes>
      <Route path="/" element={<DashBoardDueno />}>
        <Route path="/administrador" element={<ListaAdministrador />} />
        <Route path="/empleados" element={<ListaEmpleado />} />
        <Route path="/ventas-empleados" element={<VentasEmpleados />} />
      </Route>
    </Routes>
  );
}

export default RutasDueno;
