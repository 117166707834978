import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DashBoardAdministrador from '../layouts/DashBoardAdministrador.js';
import PuntoVentaAdministrador from '../formulario/formularioVentaAdministrador.js';
import VentaDiaria from '../ventas-diarias/ventasDiarias.js';

function RutasAdministrador() {
  return (
    <Routes>
      <Route path="/" element={<DashBoardAdministrador />}>
      <Route path="/punto-venta" element={<PuntoVentaAdministrador />} />
      <Route path="/ventas-diarias" element={<VentaDiaria />} />
      </Route>
    </Routes>
  );
}

export default RutasAdministrador;