import React, { useEffect, useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import Carga from '../../../componentes/cargando/Cargando'

const DashBoardAdministrador = () => {
  const navigate = useNavigate();
  const { isAuthenticated, verificacionToken } = useAuth();
  const [ cargando, setCargando ] = useState(true)

  useEffect(() => {
    verificacionToken("ROLE_ADMINISTRADOR");
    setCargando(false)
  }, []);
  
  if(cargando){
    return(
      <Carga/>
    )
  }

  return (
    <div className="container text-center">
      {isAuthenticated?(
      <div className="row">
        <div className="col-12 col-md-3">
          <div
            className="btn-group-vertical sticky-top"
            role="group"
            aria-label="Vertical button group"
          >
            <Link
              to="/administrador/punto-venta"
              className="btn btn-outline-success w-100 mb-2"
            >
              Realizar Venta
            </Link>
            <Link
              to="/administrador/ventas-diarias"
              className="btn btn-outline-success w-100 mb-2"
            >
              Ver Ventas Diarias Empleada/o
            </Link>
            <Link
              to="/administrador/ventas-locales"
              className="btn btn-outline-success w-100 mb-2"
            >
              Ver Venta Locales
            </Link>
            <button
              type="button"
              className="btn btn-outline-success w-100 mb-2"
              onClick={() => navigate("/administrador")} // Asumiendo que '/' es la ruta de inicio
            >
              Volver Principal
            </button>
          </div>
        </div>
        <div className="col-12 col-md-9">
          <Outlet /> {/* Renderiza la vista activa según la ruta */}
        </div>
      </div>)
      :(<div>Cargando...</div>)}
    </div>
  );
};

export default DashBoardAdministrador;
