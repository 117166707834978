import axios from "axios";
import Swal from "sweetalert2";
import { formatoFechaBD } from "../../helpers/formatos";

const generarVenta = async (data) => {
  try {
    const result = await Swal.fire({
      title: "¿Estás seguro/a?",
      text: "¿Quieres ingresar la venta?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
    });

    if (result.isConfirmed) {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };

      await axios.post(
        `${process.env.REACT_APP_API_URL}/administrador/empleados-venta`,
        data,
        config
      );

      Swal.fire({
        title: "¡Éxito!",
        text: "La venta se ingresó con éxito",
        icon: "success",
      });

      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
    Swal.fire({
      title: "¡Error!",
      text: "No se pudo ingresar la venta",
      icon: "error",
    });

    return false;
  }
};

const buscarVentasPorAdministrador = async (page, fechaInicio, fechaFin) => {
  const nuevaFechaInicio = `${formatoFechaBD(fechaInicio)}T00:00:00`
  const nuevaFechaFin = `${formatoFechaBD(fechaFin)}T23:59:59`
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        page: page,
        limit: 10,
        fechaInicio: nuevaFechaInicio,
        fechaFin: nuevaFechaFin
      }
    };

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/ventas/ventas-administrador`,
      config
    );
    return [response.data.ventas, 
      response.data.totalVentas, 
      response.data.totalPages, 
      response.data.currentPage];
  } catch (error) {
    return null;
  }
};

export { generarVenta, buscarVentasPorAdministrador };
