import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import { generarVenta } from "../../../service/administrador/generarVenta";
import CargandoComponente from "../../../componentes/cargando/CargandoComponentes";
import Carga from '../../../componentes/cargando/Cargando'

const PuntoVentaAdministrador = () => {
  const [empleados, setListaEmpleados] = useState([]);
  const navigate = useNavigate();
  const [ loading, setLoading ] = useState(true)
  const { verificacionToken } = useAuth();

  const [detalles, setDetalle] = useState("");
  const [
    cargandoFormularioVentaListaAdmin,
    setCargandoFormularioVentaListaAdmin,
  ] = useState(true);
  const [cargandoFormularioVentaPago, setCargandoFormularioVentaPago] =
    useState(true);
  const [precio, setPrecio] = useState([]);
  const [precioFinal, setPrecioFinal] = useState([]);
  const [metodoPago, setMetodoPago] = useState([]);
  const [idEmpleado, setIdEmpleado] = useState("");

  useEffect(() => {
    verificacionToken("ROLE_ADMINISTRADOR");
    setLoading(false)
    fetchEmpleados();
    fetchMetodoPago();
  }, []);

  const fetchEmpleados = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `${token}`,
      },
    };
    try {
      const respuesta = await axios.get(
        `${process.env.REACT_APP_API_URL}/administrador/empleados`,
        config
      );
      setListaEmpleados(respuesta.data);
      setCargandoFormularioVentaListaAdmin();
    } catch (error) {
      if (error.status === 401) {
        navigate("/expiro-sesion");
      } else if (error.status === 403) {
        navigate("/no-autorizado");
      } else {
        navigate("/no-encontrado");
      }
    }
  };

  const fetchMetodoPago = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `${token}`,
      },
    };
    try {
      const respuestaPago = await axios.get(
        `${process.env.REACT_APP_API_URL}/administrador/metodo-pago`,
        config
      );
      setMetodoPago(respuestaPago.data);
      setCargandoFormularioVentaPago(false);
    } catch (error) {
      if (error.status === 401) {
        navigate("/expiro-sesion");
      } else if (error.status === 403) {
        navigate("/no-autorizado");
      } else {
        navigate("/no-encontrado");
      }
    }
  };

  const actualizarDetallePago = (id, pago) => {
    const nuevoPago = {
      id,
      pago,
    };

    const index = precio.findIndex((p) => p.id === id);
    if (index !== -1) {
      precio[index].pago = pago;
    } else {
      precio.push(nuevoPago);
    }
    const pagosAgrupados = precio.reduce((acc, current) => {
      const { id, pago } = current;
      acc[id] = pago;

      return acc;
    }, {});
    setPrecioFinal(pagosAgrupados);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let i = 0;
    const valores = Object.values(precioFinal);
    valores.forEach((precio) => {
      i += parseInt(precio);
    });

    const data = {
      usuario_id: idEmpleado,
      detalles,
      metodoPago,
      precioFinal,
      precioTotal: i,
    };
    const v = await generarVenta(data);
    if (v) {
      navigate("/administrador/ventas-diarias");
    }
  };

  if(loading){
    return(
      <Carga/>
    )
  }

  return (
    <div>
      <div className="container mt-5">
        <h2 className="text-center mb-4">Formulario de Venta</h2>
        {empleados.length === 0 ? (
          <div>No tienes empleados a tu cargo</div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="row mb-4">
              <div>
                {cargandoFormularioVentaListaAdmin ? (
                  <CargandoComponente />
                ) : (
                  <div className="col-md-6">
                    <label htmlFor="empleado" className="form-label">
                      <h5>Trabajadora que realizó la venta</h5>
                    </label>
                    <select
                      className="form-select"
                      id="empleado"
                      value={idEmpleado}
                      onChange={(e) => setIdEmpleado(e.target.value)}
                      required
                    >
                      <option value="">Seleccione una Trabajadora</option>
                      {empleados.length === 0 ? (
                        <option key="0" value="0">
                          No tienes empleados a tu cargo
                        </option>
                      ) : (
                        empleados.map((empleado) => (
                          <option key={empleado.id} value={empleado.id}>
                            {empleado.perfil.nombre} {empleado.perfil.apellido}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12">
                <label htmlFor="detalle" className="form-label">
                  <h5>Detalle de la Venta</h5>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="detalle"
                  placeholder="Ingrese el detalle de la venta"
                  value={detalles}
                  onChange={(e) => setDetalle(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="row">
              <h5>Métodos de pagos</h5>
              <div>
                {cargandoFormularioVentaPago ? (
                  <CargandoComponente />
                ) : (
                  <div>
                    {metodoPago.map((pago) => (
                      <div key={pago.id} className="col-12 mb-4">
                        <div className="d-flex flex-column flex-md-row align-items-md-center">
                          <label
                            htmlFor={`metodoPago-${pago.id}`}
                            className="form-label me-md-3 mb-2 mb-md-0 fs-6 fs-md-4"
                            style={{ minWidth: "150px" }}
                          >
                            Pago {pago.metodoPago}:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id={`metodoPago-${pago.id}`}
                            placeholder="Ingrese el monto de las ventas"
                            value={pago.detalle}
                            onChange={(e) =>
                              actualizarDetallePago(pago.id, e.target.value)
                            }
                            required
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {(cargandoFormularioVentaListaAdmin || cargandoFormularioVentaPago) ?(
              <CargandoComponente/>
            ):(
              <div className="text-center mt-4">
              <button type="submit" className="btn btn-primary btn-lg">
                Realizar Venta
              </button>
            </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default PuntoVentaAdministrador;
