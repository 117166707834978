import axios from "axios";
import Swal from "sweetalert2";

const mensajeError = () => {
    Swal.fire({
        icon: "warning",
        title: "Ocurrio un error",
        text: "No se encontro la página. Serás redirigido a la página principal",
        allowOutsideClick: false,
        confirmButtonText: "OK"
    }).then((result) => {
        if (result.isConfirmed) {
            window.location.href = '/';
          }
    });
}

const mensajeErrorPersonalizado = (texto) => {
    Swal.fire({
        icon: "error",
        title: "Ocurrio un error",
        text: texto,
        allowOutsideClick: false,
        confirmButtonText: "OK"
    })
}

const mensajeErrorUsuarioNoEncontrado = () => {
    Swal.fire({
        icon: "error",
        title: "Ocurrio un error",
        text: "Usuario No encontrado",
        allowOutsideClick: false,
        confirmButtonText: "OK"
    })
}

const mensajeErrorBloqueo = () => {
    Swal.fire({
        icon: "warning",
        title: "Ocurrio un error",
        text: "Tu cuenta se encuentra bloqueda. Serás redirigido a la página principal",
        allowOutsideClick: false,
        confirmButtonText: "OK"
    }).then((result) => {
        if (result.isConfirmed) {
            window.location.href = '/';
          }
    });
}

const mensajeErrorSesion = () => {
    Swal.fire({
        icon: "warning",
        title: "Sesión expirada",
        text: "Tu sesión ha expirado. Serás redirigido a la página principal",
        allowOutsideClick: false,
        confirmButtonText: "OK"
    }).then((result) => {
        if (result.isConfirmed) {
            window.location.href = '/';
          }
    });
}

const mensajeErrorBloqueado = () => {
    Swal.fire({
        icon: "warning",
        title: "Cuenta Bloqueada",
        text: "Tu cuenta esta bloqueda. Serás redirigido a la página principal",
        allowOutsideClick: false,
        confirmButtonText: "OK"
    }).then((result) => {
        if (result.isConfirmed) {
            window.location.href = '/';
          }
    });
}

const mensajeErrorNoAutorizado = () => {
    Swal.fire({
        icon: "warning",
        title: "Cuenta No Autorizada",
        text: "No tienes los permisos necesarios. Serás redirigido a la página principal",
        allowOutsideClick: false,
        confirmButtonText: "OK"
    }).then((result) => {
        if (result.isConfirmed) {
            window.location.href = '/';
          }
    });
}

const mensajeErrorNoVerificado = (id) => {
    Swal.fire({
        icon: "warning",
        title: "Cuenta No Autorizada",
        text: "Tu cuenta aun no esta verificada. Mandar correo para verificación?",
        allowOutsideClick: false,
        confirmButtonText: "Si",
        confirmButtonColor: "success",
        cancelButtonText: "No",
        cancelButtonColor: "danger"
    }).then((result) => {
        const generarVerificacion = async (id) =>{{
            try{
                await axios.get(
                    `${process.env.REACT_APP_API_URL}/re-enviar-token/${id}`);
                    Swal.close()
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Se envio corretamente el correo",
                        showConfirmButton: false,
                        timer: 1500
                      });
            }catch(error){
                Swal.close()
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Hubo un problema para enviar el correo.",
                        showConfirmButton: false,
                        timer: 1500
                      });
            }
        }}
        if(result.isConfirmed){
            generarVerificacion(id)
        }
    });
}

export{
    mensajeError,
    mensajeErrorSesion,
    mensajeErrorBloqueado,
    mensajeErrorNoAutorizado,
    mensajeErrorBloqueo,
    mensajeErrorUsuarioNoEncontrado,
    mensajeErrorNoVerificado,
    mensajeErrorPersonalizado
}