import axios from "axios";
import { formatoFechaBD } from "../../helpers/formatos";

const buscarVentasPorDueno = async (page, fechaInicio, fechaFin) => {
  const nuevaFechaInicio = `${formatoFechaBD(fechaInicio)}T00:00:00`
  const nuevaFechaFin = `${formatoFechaBD(fechaFin)}T23:59:59`
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        page: page,
        limit: 10,
        fechaInicio: nuevaFechaInicio,
        fechaFin: nuevaFechaFin
      }
    };

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/dueno/buscar-ventas-empleados`,
      config
    );
    return [response.data.ventas, 
      response.data.totalVentas, 
      response.data.totalPages, 
      response.data.currentPage];
  } catch (error) {
    return null;
  }
};

export { buscarVentasPorDueno };